import { CButton, CCardFooter, CRow, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";

function AddPageFooter({ onSubmit, reset }) {
  const { t } = useTranslation();

  return (
    <CCardFooter className="border-0 pb-0 mb-4">
      {/*TODO: set disable button policy*/}
      <CRow className="justify-content-end pr-3">
        <CTooltip content={t("layout:buttons:approve")} placement="top">
          <CButton color="success" className="mr-1" onClick={onSubmit}>
            <CIcon icon="cil-check-alt" height={20} />
          </CButton>
        </CTooltip>
        {Boolean(reset) && (
          <CTooltip content={t("layout:buttons:clear")} placement="top">
            <CButton color="danger" onClick={reset}>
              <CIcon icon="cil-x" height={20} />
            </CButton>
          </CTooltip>
        )}
      </CRow>
    </CCardFooter>
  );
}

export default AddPageFooter;
