import { ApiService } from "./types";
import { IClient } from "./types/interfaces";

export class ClientService extends ApiService {
  async getAllClients() {
    const res = await this.axios.get<IClient[]>("/clients", {});
    return res.data;
  }

  // async getClient(clientUuid: string) {
  //   const res = await this.axios.get<GetClientResponse>(`/clients/${clientUuid}`, {});
  //   return res.data;
  // }
}
