import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import { useState } from "react";
import i18n from "i18next";

interface Props {
  message: string;
  onClose: () => any;
  onConfirm: () => Promise<void>;
  visible: boolean;
  confirmationText?: string;
}

function DeleteModalView({ message, visible, onClose, onConfirm, confirmationText = i18n.t("layout:delete") }: Props) {
  const [requestSent, setRequestSent] = useState<boolean>(false);
  return (
    <>
      <CModal color={"danger"} show={visible} onClose={onClose}>
        <CModalBody>{message}</CModalBody>
        <CModalFooter className="py-1">
          <CButton color="secondary" onClick={onClose}>
            {i18n.t("layout:cancel")}
          </CButton>
          <CButton
            disabled={requestSent}
            color="danger"
            onClick={() => {
              setRequestSent(true);
              onConfirm()
                .catch(() => {})
                .finally(() => {
                  setRequestSent(false);
                });
            }}
          >
            {confirmationText}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default DeleteModalView;
