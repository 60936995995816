import { Container } from "react-bootstrap";
import { CRow } from "@coreui/react";
import ImgFallback from "../../assets/images/default-fallback-image.webp";

type Partner = {
  getTranslation: Function;
  img: string;
  phone: string;
};

const Partner = (props: Partner) => {
  const { getTranslation, img, phone } = props;
  return (
    <Container>
      <CRow className=" pb-3">
        <img src={img || ImgFallback} className="gallery-item" alt="" />
      </CRow>
      <CRow className="justify-content-center">
        <h2 className="partner-page-title partner">{getTranslation("partner_name")}</h2>
      </CRow>
      <CRow className="justify-content-center">
        <h5>{phone}</h5>
      </CRow>
      <CRow>
        <p className="m-0">{getTranslation("partner_short_desc")}</p>
      </CRow>
    </Container>
  );
};

export default Partner;
