import { CRow, CCol } from "@coreui/react";
import { withTranslation, useTranslation } from "react-i18next";
import { ServiceCard } from "../../containers/UIElements";
import { getTranslation } from "../../utils/translate";
import { ReactComponent as NothingFound } from "../../assets/images/searching-data.svg";
import { useClientAuth } from "../../AuthContext";

const Services = ({ data: services, i18n: { language } }) => {
  const { client } = useClientAuth();
  return (
    <div id="partners">
      <div className="container">
        <CRow className="my-3 pt-4">
          {services && services.length > 0 ? (
            services.map(({ uuid, thumbnail_image_url, ...rest }) => (
              <CCol md={3} key={uuid}>
                <ServiceCard
                  uuid={uuid}
                  imgSrc={thumbnail_image_url}
                  title={getTranslation(language, "title", rest)}
                  text={getTranslation(language, "short_desc", rest)}
                  partnerName={getTranslation(language, "partner_name", rest)}
                  client={client}
                />
              </CCol>
            ))
          ) : (
            <SearchFallback />
          )}
        </CRow>
      </div>
    </div>
  );
};

const SearchFallback = () => {
  const { t } = useTranslation();
  return (
    <CCol md={12} className="nothing-found">
      <NothingFound className="nothing-found-svg" />
      <p className="text-uppercase">{t("layout:nothing_found")}</p>
    </CCol>
  );
};

export default withTranslation()(Services);
