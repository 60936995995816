import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon
} from "react-share";
import { INetwork } from "./types";

export const tableFields = {
  employees: ["first_name", "middle_name", "last_name", "role_id", "email", "phone_number", "action"],
  partners: [
    "first_name",
    "middle_name",
    "last_name",
    "email",
    "phone_number",
    "trips"
    // "turnover",
  ],
  clinets: [
    "first_name",
    "middle_name",
    "last_name",
    "email",
    "phone_number",
    "trips"
    // "turnover",
  ],
  services: [
    "first_name",
    "middle_name",
    "last_name",
    "email",
    "phone_number",
    "trips"
    // "turnover",
  ]
};

export const customStyles = {
  placeholder: (provider, state) => ({
    ...provider,
    color: "white"
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundImage: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
    borderRadius: "14px",
    left: 0
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundImage: `linear-gradient(to right, #6372ff 0%, ${state.isFocused ? "#6372ff" : "#5ca9fb"} 100%)`,
    color: state.isSelected ? "blue" : "white"
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    border: 0,
    outline: "none",
    boxShadow: "none"
  }),
  singleValue: (provided, state) => {
    return { ...provided, color: "white" };
  },
  menuList: (provided, state) => {
    return { ...provided, borderRadius: "14px" };
  },
  dropdownIndicator: (provided, state) => {
    return { ...provided, color: "white" };
  },
  clearIndicator: (provided, state) => {
    return { ...provided, color: "white" };
  },
  noOptionsMessage: (provided, state) => {
    return { ...provided, color: "white" };
  },
  input: (provided, state) => {
    return {
      ...provided,
      color: "white"
    };
  }
};

export const networks: INetwork[] = [
  {
    name: "facebook",
    label: "Facebook",
    button: FacebookShareButton,
    icon: FacebookIcon
  },
  {
    name: "twitter",
    label: "Twitter",
    button: TwitterShareButton,
    icon: TwitterIcon
  },
  {
    name: "linkedin",
    label: "LinkedIn",
    button: LinkedinShareButton,
    icon: LinkedinIcon
  },
  {
    name: "messenger",
    label: "Messenger",
    button: FacebookMessengerShareButton,
    icon: FacebookMessengerIcon
  },
  {
    name: "telegram",
    label: "Telegram",
    button: TelegramShareButton,
    icon: TelegramIcon
  },
  {
    name: "whatsapp",
    label: "WhatsApp",
    button: WhatsappShareButton,
    icon: WhatsappIcon
  },
  {
    name: "vk",
    label: "V Kontakte",
    button: VKShareButton,
    icon: VKIcon
  },
  {
    name: "reddit",
    label: "Reddit",
    button: RedditShareButton,
    icon: RedditIcon
  },
  // {
  //   name: "pinterest",
  //   label: "Pinterest",
  //   button: PinterestShareButton,
  //   icon: PinterestIcon
  // },

  // {
  //   name: "tumblr",
  //   label: "tumblr.",
  //   button: TumblrShareButton,
  //   icon: TumblrIcon
  // },
  {
    name: "mailru",
    label: "Mail.ru",
    button: MailruShareButton,
    icon: MailruIcon
  },
  {
    name: "ok",
    label: "Odnoklassniki",
    button: OKShareButton,
    icon: OKIcon
  }
];
