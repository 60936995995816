import { CCol, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import AboutImage from "../../assets/images/about.png";

const componentName = "landingPage:about";
const About = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="container">
        <CRow>
          <CCol xs={12} md={6}>
            <img src={AboutImage} alt="about-img" />
          </CCol>
          <CCol xs={12} md={6}>
            <div className="about-text">
              <h2>{t(`${componentName}:title`)}</h2>
              <p>{t(`${componentName}:paragraph`)}</p>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  );
};

export default About;
