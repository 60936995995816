import AuthService from "./api/AuthService";
import StorageService from "./api/StorageService";
import { ClientService } from "./api/ClientService";
import LandingPageService from "../landing/services/LandginPageService";
import { PartnerService } from "./api/PartnerService";
import { ServiceService } from "./api/ServiceService";

// These should be explicitly exported
// because ApiServices use them but can't access before all import is ok
// and for avoiding recursion
export const storageService = new StorageService();
const authService = new AuthService();
const clientService = new ClientService();
const partnerService = new PartnerService();
const landingPageService = new LandingPageService();
const serviceService = new ServiceService();
const getInstances = {
  getAuthService() {
    return authService;
  },
  getStorageService() {
    return storageService;
  },
  getClientService() {
    return clientService;
  },
  getPartnerService() {
    return partnerService;
  },
  getLandingPageService() {
    return landingPageService;
  },
  getServiceService() {
    return serviceService;
  }
};

export default getInstances;
