import { useState, lazy, Suspense } from "react";
import { Page404, Page500 } from "../containers";
import { Header, About, Services, Filter } from "./modules";
import { useRoutes } from "react-router-dom";
import getInstances from "../utils";
import "../scss/landing.scss";

// import SmoothScroll from "smooth-scroll";
//
// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const ServicePage = lazy(() => import("./ServicePage"));
const api = getInstances.getLandingPageService();
const LandingPage = (props) => {
  const [services, setServices] = useState<any[]>();

  const fetchServices = ({ city, category }) => {
    api
      .getAllServices(city.id, category.id)
      .then((data) => setServices(data))
      .catch((err) => console.log(err));
  };

  const landingPageRoutes = useRoutes([
    {
      path: "",
      element: (
        <>
          <Header />
          <Filter fetch={fetchServices} />
          <Services data={services} />
          <About />
        </>
      )
    },
    {
      path: "services/:uuid",
      element: <Suspense fallback={loading} children={<ServicePage />} />
    },
    { path: "*", element: <Page404 /> },
    { path: "500", element: <Page500 /> }
  ]);

  return landingPageRoutes;
};

export default LandingPage;
