import { Fragment } from "react";
import ImgFallback from "../../assets/images/default-fallback-image.webp";
import "../../scss/service-card.scss";

const ImgSlider = ({ images }) => {
  return (
    <Fragment>
      {images.map(({ order_num }) => (
        <input
          key={`radio-${order_num}`}
          type="radio"
          name="slider"
          id={`item-${order_num + 1}`}
          defaultChecked={order_num === 0}
        />
      ))}
      <div className="info-cards">
        <div className="arrow-icons-container" style={{ display: images.length > 1 ? "flex" : "none" }}>
          <i className="fa fa-angle-left arrow-icon" />
          <i className="fa fa-angle-right arrow-icon" />
        </div>
        {images.map(({ url, order_num }) => (
          <label
            key={`label-${order_num}`}
            className="info-card"
            htmlFor={`item-${order_num + 1}`}
            id={`img-${order_num + 1}`}
          >
            <img src={url || ImgFallback} className="gallery-item" alt="" />
          </label>
        ))}
      </div>
    </Fragment>
  );
};

export default ImgSlider;
