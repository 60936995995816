import { ApiService } from "./types";
import {
  GetAllServicesResponse,
  GetServiceResponse,
  GetAllServicePostsResponse,
  GetServicePostResponse,
  CreateServicePostRequest,
  CreateServicePostResponse,
  EditServicePostRequest,
  EditServicePostResponse,
  DeleteServicePostResponse
} from "./types/responseTypes/Service";
import { IService } from "./types/interfaces";

export class ServiceService extends ApiService {
  async getAllServices() {
    const res = await this.axios.get<GetAllServicesResponse>("/services", {});
    return res.data;
  }

  async getService(serviceUuid: string) {
    const res = await this.axios.get<GetServiceResponse>(`/services/${serviceUuid}`, {});
    return res.data;
  }

  async getAllServicePosts() {
    const res = await this.axios.get<GetAllServicePostsResponse>("/services/posts", {});
    return res.data as IService[];
  }

  async getServicePost(serviceUuid: string) {
    const res = await this.axios.get<GetServicePostResponse>(`/services/${serviceUuid}/post`, {});
    return res.data as any;
  }

  async createServicePost(formData: FormData) {
    const res = await this.axios.post<CreateServicePostResponse>(
      "/services/posts",
      formData,
      undefined,
      {
        "Content-Type": "multipart/form-data"
      }
    );
    return res.data;
  }

  async editServicePost(serviceUuid: string, params: EditServicePostRequest) {
    const res = await this.axios.put<EditServicePostResponse>(
      `/services/${serviceUuid}/post`,
      params
    );
    return res.data;
  }

  async deleteServicePost(serviceUuid: string) {
    const res = await this.axios.delete<DeleteServicePostResponse>(
      `/services/${serviceUuid}/post`,
      {}
    );
    return res.data;
  }
}
