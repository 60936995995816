import React, { useEffect, useRef, useState } from "react";
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import ServiceFactory from "../../utils";
import { useForm } from "react-hook-form";
import { Input, SuccessHandler } from "../../containers/UIElements";
import { Navigate, useNavigate } from "react-router-dom";
import { useAdminAuth, useUpdateAdminAuth } from "../../AuthContext";

interface FormData {
  phone: string;
  password: string;
}

const api = ServiceFactory.getAuthService();

const Login = () => {
  const { t } = useTranslation();
  const auth = useAdminAuth();
  // const updateAuth = useUpdateAdminAuth();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormData>();
  const [isRequestSent, setRequestSent] = useState<boolean>(false);
  const isMounted = useRef<boolean>(false);

  // useEffect(() => {
  //   // isMounted.current = true;
  //   // return () => {
  //   //   isMounted.current = false;
  //   // };
  // }, []);

  const onSubmit = handleSubmit(async ({ phone, password }: FormData) => {
    console.log("login cliuck!");
    // setRequestSent(true);
    try {
      await api
        .loginEmployee({
          phoneNumber: phone,
          password
        })
        .then(() => {
          navigate("/admin");
          // updateAuth();
          // SuccessHandler(
          //   t("alerts:success_messages:title"),
          //   t("alerts:success_messages:add", {
          //     user: t(`k:employee`)
          //   })
          // );
        });
    } catch {
    } finally {
      if (isMounted.current) setRequestSent(false);
    }
  });

  // if (auth.admin) return <Navigate to={"/admin"} replace />;

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={onSubmit}>
                    <h1>{t("login:title")}</h1>
                    <p className="text-muted">{t("login:sign_in")}</p>
                    {[
                      { name: "phone", icon: "cil-phone", type: "phone" },
                      { name: "password", icon: "cil-lock-locked", type: "password" }
                    ].map(({ name, icon, type }) => (
                      <Input
                        key={name}
                        inputMd={12}
                        prepend={<CIcon icon={icon} className="auth-icon" />}
                        control={control}
                        name={name}
                        type={type || name}
                        placeholder={t(`login:fields:${name}:placeholder`)}
                        required={t(`login:fields:${name}:required`)}
                        min={0}
                      />
                    ))}
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" disabled={isRequestSent} className="px-4" type="submit">
                          {t("login:login")}
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton to="/forgot-password" color="link" className="px-0">
                          {t("login:forgot_password")}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
