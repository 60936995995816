import { useEffect, useState, Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import Login from "./admin/login/Login";
// import Register from "./adminPanel/register/Register";
import Page404 from "./containers/Page404";
import Page500 from "./containers/Page500";
// import ProtectedRoute from "./containers/ProtectedRoute";
// import ForwardRoute from "./containers/ForwardRoute";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "./containers/UIElements";
import ServiceFactory from "./utils";
// import ForgotPassword from "./adminPanel/forgotPassword/ForgotPassword";
// import ResetPassword from "./adminPanel/forgotPassword/ResetPassword";
// import { ToastContainer } from "react-toastify";
import "./utils/translate";
import Navigation from "./landing/modules/Navigation";
import LandingPage from "./landing/LandingPage";
import Contact from "./landing/modules/Contact";
import Footer from "./landing/modules/Footer";
import ProtectedRoute from "./containers/ProtectedRoute";
import { ClientAuthProvider, AdminAuthProvider } from "./AuthContext";

// const auth = ServiceFactory.getAuthService();
// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse" />
//   </div>
// );

const AdminPanel = lazy(() => import("./admin/AdminPanel"));

function App() {
  // const [isLoading, setLoading] = useState<boolean>(true);
  // useEffect(() => {

  //   // api.getCustomers().then((data) => {
  //   //   console.log("inside api: ", data)
  //   // }).catch(err => console.log(err));
  // }, []);

  const routes = useRoutes([
    {
      path: "/*",
      element: (
        <ClientAuthProvider>
          <Navigation />
          <LandingPage />
          <Contact />
          <Footer />
        </ClientAuthProvider>
      )
    },
    {
      path: "admin/login",
      element: <Login />
    },
    {
      path: "admin/*",
      element: (
        // <Suspense fallback={loading}>

        <AdminPanel />

        // </Suspense>
      )
    }
  ]);

  // useEffect(() => {
  //   auth
  //     .startupChecks()
  //     .catch(() => {})
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  return routes;
}

export default App;
