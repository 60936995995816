import { CCol, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";

const componentName = "landingPage:contact";
const Contact = () => {
  const { t } = useTranslation();

  return (
    <div id="contact" className="py-5">
      <div className="container">
        <div className="section-title">
          <h2>{t(`${componentName}:title`)}</h2>
        </div>
        <CRow className="justify-content-between">
          <CRow className="mx-1">
            {CONTACTS.map(({ fieldName, icon }) => (
              <CCol md={3} key={fieldName}>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className={`fa fa-${icon}`} />
                      {t(`${componentName}:${fieldName}`)}
                    </span>
                    {t(`${componentName}:info:${fieldName}`)}
                  </p>
                </div>
              </CCol>
            ))}
          </CRow>
        </CRow>
        <CRow className="justify-content-center social">
          <ul>
            {SOCIAL_MEDIA.map(({ name, page }) => (
              <li key={name}>
                <a href={page} target="_blank">
                  <i className={`fa fa-${name}`} />
                </a>
              </li>
            ))}
          </ul>
        </CRow>
      </div>
    </div>
  );
};

const CONTACTS = Object.freeze<{ fieldName: string; icon: string }[]>([
  { fieldName: "address", icon: "map-marker" },
  { fieldName: "phone", icon: "phone" },
  { fieldName: "email", icon: "envelope-o" },
  { fieldName: "donation", icon: "credit-card" }
]);

const SOCIAL_MEDIA = Object.freeze<{ name: string; page: string }[]>([
  { name: "facebook", page: "https://www.fb.com" },
  { name: "instagram", page: "https://www.instagram.com/forsetiworld/" },
  { name: "youtube", page: "https://www.youtube.com" }
]);

export default Contact;
