import i18n from "i18next";
import en from "../assets/locales/en.json";
import az from "../assets/locales/az.json";
import ru from "../assets/locales/ru.json";
import { initReactI18next } from "react-i18next";

// gets right object on language change from the different translations. e.g. translations = {text_az, text_en, text_ru}
export function getTranslation(language: string, prefix: string, translations: object) {
  // change 'gb' to 'en' and construct key
  const key = prefix + "_" + (language === "gb" ? "en" : language);

  return translations[key];
}

i18n.use(initReactI18next).init({
  fallbackLng: "az",
  lng: "az",
  debug: false,
  resources: {
    az: az,
    gb: en,
    ru: ru
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
