import { Card } from "react-bootstrap";
import { CRow, CButton, CCol, CForm, CSpinner, CAlert } from "@coreui/react";
import { useTranslation } from "react-i18next";
import ImgFallback from "../../assets/images/default-fallback-image.webp";
import RankingStars from "./RankingStars";
import withModal from "./HOCs/withModal";
import { LANDING } from "../../landing/constants";
import "../../scss/service-card.scss";
import { useForm } from "react-hook-form";
import Input from "./Input";
import CIcon from "@coreui/icons-react";
import getInstances from "../../utils";
import { useState } from "react";

// TODO: remove no getInstances bug
const componentName = "landingPage:card";
const fields = ["city_id", "country_id", "first_name", "last_name", "middle_name", "phone_number"];
const api = getInstances?.getLandingPageService();
const UseService = ({ uuid, client }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [usageSuccess, setUsageSuccess] = useState(false);
  const [usageFail, setUsageFail] = useState(false);

  const { control, handleSubmit } = useForm();

  const onSubmit = handleSubmit(({ secret }) => {
    setLoading(true);
    usageSuccess && setUsageSuccess(false);
    usageFail && setUsageFail(false);

    api
      ?.useService(uuid, secret)
      .then((data) => {
        setUsageSuccess(true);
      })
      .catch(() => setUsageFail(true))
      .finally(() => setLoading(false));
  });

  return (
    <CForm onSubmit={onSubmit}>
      <Input
        inputMd={12}
        label={t(`${componentName}:title`)}
        prepend={<CIcon icon="cil-asterisk" className="auth-icon" />}
        control={control}
        name={"secret"}
        placeholder={""}
      />

      {isLoading ? (
        <CRow alignHorizontal="center">
          <CSpinner />
        </CRow>
      ) : usageSuccess ? (
        <>
          <CAlert color="success">A simple success alert—check it out!</CAlert>
          {fields.map((fieldName) => (
            <Input
              key={fieldName}
              inputMd={12}
              label={t(`adminPanel:clients:${fieldName}`)}
              control={control}
              defaultValue={
                fieldName.includes("city")
                  ? t(`cities:${client[fieldName]}`)
                  : fieldName.includes("country")
                  ? t(`countries:${client[fieldName]}`)
                  : client[fieldName]
              }
              name={fieldName}
              placeholder=""
              disabled
            />
          ))}
        </>
      ) : usageFail ? (
        <CAlert color="danger">A simple danger alert—check it out!</CAlert>
      ) : null}
      <CRow>
        <CCol xs="6" className="pr-0" />
        <CCol xs="6" className="text-right pl-0">
          <CButton color="primary" className="px-4" type="submit">
            {t(`${componentName}:confirm`)}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

const ServiceCard = ({ uuid, imgSrc, title, text, modal, setModal, partnerName, client }) => {
  const { t } = useTranslation();

  return (
    <Card className="partner-card shadow border-round-25">
      <Card.ImgOverlay className="d-flex justify-content-center">
        <div className="overlay-content  flex-column  justify-content-center">
          <CButton className="btn-custom mb-2" onClick={() => window.open("/services/" + uuid, "_blank")}>
            DAHA ƏTRAFLI
          </CButton>
          {/* <CButton
            className="btn-custom"
            onClick={() => {
              if (!client) {
                // TODO: work to remove antipattern
                const loginModal = document.getElementById("login-modal");
                if (loginModal) loginModal.click();
              } else {
                setModal({
                  ...modal,
                  show: true,
                  title: <></>,
                  body: <UseService uuid={uuid} client={client} />
                });
              }
            }}
          >
            İSTİFADƏ ET
          </CButton> */}
        </div>
      </Card.ImgOverlay>
      <Card.Img src={imgSrc || ImgFallback} variant="top" className="border-top-round-25" />
      {/*TODO: set max and min height to be equal to achieve uniform height across columns and rows*/}
      <Card.Body>
        <Card.Title className="font-weight-bold text-dark">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
      <Card.Footer className="border-round-25">
        <CRow className="justify-content-between px-3">
          <RankingStars points={5} />
          <div>
            <span>{partnerName}</span>
            {/* <i className="fa fa-users" style={{ color: "gray", fontSize: ".8rem" }} /> */}
          </div>
        </CRow>
      </Card.Footer>
    </Card>
  );
};

export default withModal(ServiceCard, LANDING);
