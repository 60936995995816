import { tableFields } from "../../containers/UIElements/constants";

export default class StorageService {
  public updateActiveTableFields(tableName: string, fields: string[]) {
    localStorage.setItem(tableName, JSON.stringify(fields));
  }

  private getFields(tableName: string): string[] {
    return tableFields[tableName];
  }

  public getActiveTableFields(tableName: string): string[] {
    try {
      let fields = localStorage.getItem(tableName);
      if (fields == null) throw new Error();
      return JSON.parse(fields);
    } catch {
      return this.getFields(tableName);
    }
  }

  public removeActiveTableFields(tableName: string) {
    // if there is active table fields in storage remove
    if (this.getActiveTableFields(tableName) != null) {
      localStorage.removeItem(tableName);
    }
  }

  private setPermissions(permissions: any[]) {
    localStorage.setItem("permissions", JSON.stringify(permissions));
  }

  public getPermissions(): any[] | null {
    const permissions = localStorage.getItem("permissions");
    if (permissions !== null) {
      const data = JSON.parse(permissions);
      return Array.isArray(data) ? data : null;
    }
    return null;
  }

  private removePermissions() {
    localStorage.removeItem("permissions");
  }
}
