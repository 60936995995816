import i18next from "i18next";
import { DangerHandler } from "../../containers/UIElements";

//type Error = AxiosError & AxiosResponse<unknown>;
type Error = any;
const ErrorHandler = (error: Error) => {
  // error could be real axios error or response that manually thrown
  const err = error.response ?? error;

  if (err) {
    // does error have message
    const err_code = "errors:" + err.data.message;
    if (i18next.exists(err_code)) DangerHandler(i18next.t("alerts:error_messages:title"), i18next.t(err_code));
    // else DangerHandler(i18next.t("alerts:error_messages:title"));

    // if response not available then error probably is network error
  } else if (error.message) {
    if (error.message === "Network Error")
      DangerHandler(i18next.t("alerts:error_messages:title"), i18next.t("errors:network_error"));
    // else DangerHandler(i18next.t("alerts:error_messages:title"), error.message);
  }
};
export default ErrorHandler;
