import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../constants";
import ErrorHandler from "./ErrorHandler";

type CallOptions = {
  handleError: boolean;
};

const defaultCallOptions: CallOptions = {
  handleError: true
};

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" }
};

class CustomAxios {
  private axios = axios.create(axiosConfig);

  constructor() {
    this.axios.interceptors.request.use(
      (config) => {
        const userToken = CustomAxios.injectAuth();
        if (userToken != null && config.headers) config.headers["Authorization"] = userToken;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axios.interceptors.response.use(
      (res) => res,
      (error) => {
        // Unauthorized
        if (error.response.status === 401) {
          // this.redux.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  private static injectAuth(): string | undefined {
    // const { user } = store.getState();
    let authHeader: string | undefined;
    // if (user.isLogged) {
    //   authHeader = `Bearer ${user.auth_token}`;
    // }
    return authHeader;
  }

  public async get<T>(
    url: string,
    params: AxiosRequestConfig["params"],
    opts: CallOptions = defaultCallOptions
  ) {
    try {
      return await this.axios.get<T>(url, { params });
    } catch (error) {
      if (opts.handleError) {
        ErrorHandler(error);
      }
      throw error;
    }
  }

  public async post<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    opts: CallOptions = defaultCallOptions,
    headers?: AxiosRequestConfig["headers"]
  ) {
    try {
      return await this.axios.post<T>(url, data, {
        headers
      });
    } catch (error) {
      if (opts.handleError) {
        ErrorHandler(error);
      }
      throw error;
    }
  }

  public async put<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    opts: CallOptions = defaultCallOptions,
    headers?: AxiosRequestConfig["headers"]
  ) {
    try {
      return await this.axios.put<T>(url, data, {
        headers
      });
    } catch (error) {
      if (opts.handleError) {
        ErrorHandler(error);
      }
      throw error;
    }
  }

  public async patch<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    opts: CallOptions = defaultCallOptions
  ) {
    try {
      return await this.axios.patch<T>(url, data);
    } catch (error) {
      if (opts.handleError) {
        ErrorHandler(error);
      }
      throw error;
    }
  }

  public async delete<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    opts: CallOptions = defaultCallOptions
  ) {
    try {
      return await this.axios.delete<T>(url, data);
    } catch (error) {
      if (opts.handleError) {
        ErrorHandler(error);
      }
      throw error;
    }
  }
}
export default CustomAxios;
