import { TFunction } from "i18next";

export const API_URL = process.env.NODE_ENV === "production" ? "https://api.forsetiworld.com" : "http://localhost:8080";

export type { RawCountry, RawCity, RawCategory, CountryList, CityList, CategoryList };

export { rawCountriesDict, rawCitiesDict, rawCategoriesDict, countries, cities, categories, localeCompare };

type RawCountry = {
  name: string;
};

type RawCity = {
  name: string;
  country_id: number;
};

type RawCategory = {
  name: string;
  parent_id?: number;
  icon?: string;
};

type CountryList = {
  id: number;
  name: string;
  cities: CityList;
}[];

type CityList = {
  id: number;
  name: string;
}[];

type CategoryList = {
  id: number;
  name: string;
  icon?: string;
  subcategories: SubcategoryList;
}[];

type SubcategoryList = {
  id: number;
  name: string;
}[];

type TempSubcategoryList = {
  id: number;
  name: string;
  parent_id: number;
}[];

const rawCountriesDict: Record<number, RawCountry> = {
  994: { name: "Azerbaijan" },
  995: { name: "Georgia" },
  90: { name: "Turkey" }
};

const rawCitiesDict: Record<number, RawCity> = {
  99412: { name: "Bakı", country_id: 994 },
  994123: { name: "Abşeron", country_id: 994 },
  994186: { name: "Sumqayıt", country_id: 994 },
  9942020: { name: "Bərdə", country_id: 994 },
  9942021: { name: "Ucar", country_id: 994 },
  9942022: { name: "Ağsu", country_id: 994 },
  9942023: { name: "Ağdaş", country_id: 994 },
  9942024: { name: "Qobustan", country_id: 994 },
  9942025: { name: "Kürdəmir", country_id: 994 },
  9942026: { name: "Şamaxı", country_id: 994 },
  9942027: { name: "Göyçay", country_id: 994 },
  9942028: { name: "İsmayıllı", country_id: 994 },
  9942029: { name: "Zərdab", country_id: 994 },
  9942120: { name: "Hacıqabul", country_id: 994 },
  9942121: { name: "Şirvan", country_id: 994 },
  9942122: { name: "Beyləqan", country_id: 994 },
  9942123: { name: "Sabirabad", country_id: 994 },
  9942124: { name: "İmişli", country_id: 994 },
  9942125: { name: "Salyan", country_id: 994 },
  9942126: { name: "Neftçala", country_id: 994 },
  9942127: { name: "Ağcabədi", country_id: 994 },
  9942128: { name: "Saatlı", country_id: 994 },
  9942220: { name: "Göygöl", country_id: 994 },
  9942221: { name: "Daşkəsən", country_id: 994 },
  9942222: { name: "Ağstafa", country_id: 994 },
  9942223: { name: "Tərtər", country_id: 994 },
  9942224: { name: "Goranboy", country_id: 994 },
  9942225: { name: "Gəncə", country_id: 994 },
  9942227: { name: "Samux", country_id: 994 },
  9942229: { name: "Qazax", country_id: 994 },
  9942230: { name: "Şəmkir", country_id: 994 },
  9942231: { name: "Tovuz", country_id: 994 },
  9942232: { name: "Gədəbəy", country_id: 994 },
  9942233: { name: "Yevlax", country_id: 994 },
  9942235: { name: "Naftalan", country_id: 994 },
  9942330: { name: "Siyəzən", country_id: 994 },
  9942331: { name: "Xızı", country_id: 994 },
  9942332: { name: "Xaçmaz", country_id: 994 },
  9942333: { name: "Quba", country_id: 994 },
  9942335: { name: "Şabran", country_id: 994 },
  9942338: { name: "Qusar", country_id: 994 },
  9942420: { name: "Qəbələ", country_id: 994 },
  9942421: { name: "Oğuz", country_id: 994 },
  9942422: { name: "Zaqatala", country_id: 994 },
  9942424: { name: "Şəki", country_id: 994 },
  9942425: { name: "Qax", country_id: 994 },
  9942427: { name: "Mingəçevir", country_id: 994 },
  9942429: { name: "Balakən", country_id: 994 },
  9942520: { name: "Yardımlı", country_id: 994 },
  9942521: { name: "Masallı", country_id: 994 },
  9942522: { name: "Astara", country_id: 994 },
  9942524: { name: "Cəlilabad", country_id: 994 },
  9942525: { name: "Lənkəran", country_id: 994 },
  9942527: { name: "Lerik", country_id: 994 },
  9942529: { name: "Biləsuvar", country_id: 994 },
  9942620: { name: "Xocalı", country_id: 994 },
  9942621: { name: "Laçın", country_id: 994 },
  9942622: { name: "Xankəndi", country_id: 994 },
  9942623: { name: "Qubadlı", country_id: 994 },
  9942624: { name: "Əskəran", country_id: 994 },
  9942625: { name: "Zəngilan", country_id: 994 },
  9942626: { name: "Şuşa", country_id: 994 },
  9942627: { name: "Kəlbəcər", country_id: 994 },
  9942628: { name: "Ağdərə", country_id: 994 },
  9942629: { name: "Xocavənd", country_id: 994 },
  9942630: { name: "Hadrut", country_id: 994 },
  9942631: { name: "Füzuli", country_id: 994 },
  9942632: { name: "Ağdam", country_id: 994 },
  9942638: { name: "Cəbrayıl", country_id: 994 },
  994365: { name: "Naxçıvan", country_id: 994 },
  99436541: { name: "Babək", country_id: 994 },
  99436542: { name: "Şərur", country_id: 994 },
  99436543: { name: "Şahbuz", country_id: 994 },
  99436546: { name: "Culfa", country_id: 994 },
  99436547: { name: "Ordubad", country_id: 994 },
  99436548: { name: "Kəngərli", country_id: 994 },
  99436549: { name: "Sədərək", country_id: 994 },
  90212: { name: "Istanbul", country_id: 90 }
};

const rawCategoriesDict: Record<number, RawCategory> = {
  101: { name: "education", icon: "fa fa-graduation-cap" },
  102: { name: "medicine", icon: "fa fa-user-md" },
  102001: { name: "obstetrician-gynecologist", parent_id: 102 },
  102002: { name: "allergist", parent_id: 102 },
  102003: { name: "andrologist", parent_id: 102 },
  102004: { name: "dermatologist", parent_id: 102 },
  102005: { name: "dietitian", parent_id: 102 },
  102006: { name: "endocrinologist", parent_id: 102 },
  102007: { name: "hematologist", parent_id: 102 },
  102008: { name: "infectiologist", parent_id: 102 },
  102009: { name: "cardiologist", parent_id: 102 },
  102010: { name: "cosmetologist", parent_id: 102 },
  102011: { name: "laboratory", parent_id: 102 },
  102012: { name: "neurologist", parent_id: 102 },
  102013: { name: "neuropathologist", parent_id: 102 },
  102014: { name: "oncologist", parent_id: 102 },
  102015: { name: "orthopedist", parent_id: 102 },
  102016: { name: "otolaryngologist", parent_id: 102 },
  102017: { name: "ophthalmologist", parent_id: 102 },
  102018: { name: "pediatrician", parent_id: 102 },
  102019: { name: "proctologist", parent_id: 102 },
  102020: { name: "psychiatrist", parent_id: 102 },
  102021: { name: "psychotherapist", parent_id: 102 },
  102022: { name: "pulmonologist", parent_id: 102 },
  102023: { name: "gastroenterologist", parent_id: 102 },
  102024: { name: "rheumatologist", parent_id: 102 },
  102025: { name: "radiography", parent_id: 102 },
  102026: { name: "dentist", parent_id: 102 },
  102027: { name: "traumatologist", parent_id: 102 },
  102028: { name: "tomography", parent_id: 102 },
  102029: { name: "urologist", parent_id: 102 },
  102030: { name: "use", parent_id: 102 },
  102031: { name: "vertebrologist", parent_id: 102 },
  103: { name: "entertainment", icon: "fa fa-gamepad" },
  104: { name: "jurisdiction", icon: "fa fa-balance-scale" },
  105: { name: "tourism", icon: "fa fa-plane" },
  106: { name: "sports", icon: "fa fa-futbol-o" }
};

function parseCountriesDict(): CountryList {
  const rawCountryIds = Object.keys(rawCountriesDict);
  const len = rawCountryIds.length;
  const arr: CountryList = new Array(len);
  const cities: CityList = parseCitiesDict();

  for (let i = 0; i < len; i++) {
    const countryId = Number(rawCountryIds[i]);

    if (countryId < 1) {
      throw new Error("invalid_countries_dict_key");
    }

    const rawCountry: RawCountry = rawCountriesDict[countryId];
    const countryCities: CityList = cities.filter((city) => rawCitiesDict[city.id].country_id === countryId);

    arr[i] = {
      id: countryId,
      name: rawCountry.name,
      cities: countryCities
    };
  }

  return arr;
}

function parseCitiesDict(): CityList {
  const rawCityIds = Object.keys(rawCitiesDict);
  const len = rawCityIds.length;
  const arr: CityList = new Array(len);

  for (let i = 0; i < len; i++) {
    const cityId = Number(rawCityIds[i]);

    if (cityId < 1) {
      throw new Error("invalid_cities_dict_key");
    }

    const rawCity: RawCity = rawCitiesDict[cityId];

    arr[i] = {
      id: cityId,
      name: rawCity.name
    };
  }

  return arr;
}

function parseCategoriesDict(): CategoryList {
  const rawCategoryIds = Object.keys(rawCategoriesDict);
  const mainCategoryIds = rawCategoryIds.filter((categoryId) => !rawCategoriesDict[Number(categoryId)].parent_id);
  const subcategoryIds = rawCategoryIds.filter((categoryId) => rawCategoriesDict[Number(categoryId)].parent_id);
  const len = rawCategoryIds.length;
  const mainLen = mainCategoryIds.length;
  const subLen = len - mainLen;
  const arr: CategoryList = new Array(mainLen);
  const subcategories: TempSubcategoryList = new Array(subLen);

  for (let i = 0; i < subLen; i++) {
    const categoryId = Number(subcategoryIds[i]);

    if (categoryId < 1) {
      throw new Error("invalid_categories_dict_key");
    }

    const rawCategory: RawCategory = rawCategoriesDict[categoryId];

    subcategories[i] = {
      id: categoryId,
      name: rawCategory.name,
      parent_id: rawCategory.parent_id || 0
    };
  }

  for (let i = 0; i < mainLen; i++) {
    const categoryId = Number(mainCategoryIds[i]);

    if (categoryId < 1) {
      throw new Error("invalid_categories_dict_key");
    }

    const rawCategory: RawCategory = rawCategoriesDict[categoryId];
    const categorySubcategories: SubcategoryList = subcategories
      .filter((subcategory) => subcategory.parent_id === categoryId)
      .map((subcategory) => ({ id: subcategory.id, name: subcategory.name }));

    arr[i] = {
      id: categoryId,
      name: rawCategory.name,
      icon: rawCategory.icon,
      subcategories: categorySubcategories
    };
  }

  return arr;
}

function findRawCitiesByCountryId(countryId: number): RawCity[] {
  return Object.values(rawCitiesDict).filter((rawCity) => rawCity.country_id === countryId);
}

function localeCompare(t: TFunction, tag: string, name = "id") {
  return (a: any, b: any) => t(`${tag}:${a[name]}`).localeCompare(t(`${tag}:${b[name]}`));
}

const countries: CountryList = parseCountriesDict();
const cities: CityList = parseCitiesDict();
const categories: CategoryList = parseCategoriesDict();
