import React from "react";
import { CCardGroup, CCard, CCardBody, CRow, CCol } from "@coreui/react";
import { networks } from "./constants";
import { INetwork, ISocialNetwork, ISocialButton, SSButtonProps, SSIconProps } from "./types";

type Props = {
  shareUrl: string; // global url
  iconSize?: number; // global size
  round?: boolean; // global shape
  format?: number[]; // format [rows, columns], where rows >=0 and columns >=0 and rows*columns <= 12
  buttons?: ISocialButton[]; // buttons: IShareButton[] for customizing buttons,
  buttonConfigs?: SSButtonProps; // global extra button configs
  iconConfigs?: SSIconProps; // global extra icon configs
};

const DEFAULT_FORMAT = [2, 5];
const SocialShare = ({ shareUrl = "", iconSize = 50, round = true, format = DEFAULT_FORMAT, buttons = [], buttonConfigs, iconConfigs }: Props) => {
  let socialNetworks: (INetwork | ISocialNetwork)[] = networks;

  // check format to be length of 2, rows >= 0, columns >= 0, rows*columns <= 12
  if (format.length !== 2 || format[0] < 0 || format[1] < 0 || (format !== DEFAULT_FORMAT && format[0] * format[1] > 12)) {
    format = DEFAULT_FORMAT;
  }

  if (buttons.length > 0) {
    // find intersections of buttons and networks and assign them to socialNetworks
    // note that if buttons.length > 0 we must find at least one intersection
    const commonNetworks: ISocialNetwork[] = buttons.map((button) => {
      const commonNetwork: INetwork | undefined = networks.find((network) => network.name === button.name);

      // resulting object will have button and icon components from networks
      // and name, label, buttonConfigs, iconConfigs from buttons
      return { ...commonNetwork, ...button } as ISocialNetwork;
    });

    // commonNetworks may have length < 12, which means format must be adjusted accordingly
    // it would be enough to check if format to be rows*columns <= length
    if (format[0] * format[1] > commonNetworks.length) {
      console.error(
        "You provided the wrong format, as number of valid buttons does not coincide with provided format! Number of valid buttons must be less or equal to the product of values of format array.",
        "Format array: ",
        format,
        "Valid buttons: ",
        commonNetworks
      );
    } else socialNetworks = commonNetworks;
  }

  // check if object is type of ISocialNetwork
  function instanceOfISocialNetwork(object: any): object is ISocialNetwork {
    return "button" in object && "buttonConfigs" in object;
  }

  return (
    <CCardGroup>
      <CCard className="p-2 border-0">
        <CCardBody className="p-0">
          <CCol>
            {Array.from(Array(format[0]).keys()).map((rowIndex: number) => {
              const start = rowIndex * format[1];
              const end = start + format[1];

              return (
                <CRow key={`social-share-row-${rowIndex}`} className="p-1 d-flex justify-content-around">
                  {socialNetworks.slice(start, end).map((network: INetwork | ISocialNetwork, index: number) => {
                    let iconProps, buttonProps;

                    // if network of type ISocialNetwork then take custom icon, social button configs else take global configs
                    if (instanceOfISocialNetwork(network)) {
                      // custom configs
                      iconProps = network?.iconConfigs;
                      buttonProps = network.buttonConfigs;
                    } else {
                      // global configs
                      iconProps = { ...iconConfigs, size: iconSize, round };
                      buttonProps = getButtonPropsWithConfigs(network.name, { ...buttonConfigs?.all, url: shareUrl }, buttonConfigs);
                    }

                    return (
                      <CCol key={`social-share-column-${index}`} className="center-block text-center">
                        <network.button {...buttonProps} className="p-1">
                          <network.icon {...iconProps} />
                        </network.button>
                        {network.label && <span style={{ whiteSpace: "nowrap" }}>{network.label}</span>}
                      </CCol>
                    );
                  })}
                </CRow>
              );
            })}
          </CCol>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
};

const getButtonPropsWithConfigs = (name: string, props: object, configs?: SSButtonProps) => {
  switch (name) {
    case "facebook":
      props = { ...props, ...configs?.facebook };
      break;
    case "linkedin":
      props = { ...props, ...configs?.linkedin };
      break;
    case "mailru":
      props = { ...props, ...configs?.mailru };
      break;
    case "ok":
      props = { ...props, ...configs?.ok };
      break;
    case "reddit":
      props = { ...props, ...configs?.reddit };
      break;
    case "telegram":
      props = { ...props, ...configs?.telegram };
      break;
    case "tumblr":
      props = { ...props, ...configs?.tumblr };
      break;
    case "twitter":
      props = { ...props, ...configs?.twitter };
      break;
    case "vk":
      props = { ...props, ...configs?.vk };
      break;
    case "whatsapp":
      props = { ...props, ...configs?.whatsapp };
      break;
    case "messenger":
      props = { ...props, appId: "", ...configs?.messenger };
      break;
    case "pinterest":
      props = { ...props, media: "path-to-image", ...configs?.pinterest };
      break;
  }

  return props;
};

export default SocialShare;
