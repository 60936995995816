import { SubmitHandler, useForm } from "react-hook-form";
import { CButton, CButtonGroup, CCardHeader, CCol, CDataTable, CRow, CTooltip } from "@coreui/react";
import i18n from "i18next";
import CIcon from "@coreui/icons-react";
import React from "react";
import { TableFilter, TableProps } from "./types";
import Select from "./Select";
import Input from "./Input";
import dayjs from "dayjs";
import { ReactComponent as Excel } from "../../assets/icons/exls.svg";
import Form from "./Form";

// Component includes table, date filter, key-value filter and pagination
// all views except table view are optional and can be removed if proper
// flags are used.
const Table = ({
  className,
  overflowHidden = true,
  items,
  loading,
  tableFields,
  responsive = true,
  itemsPerPage = 15,
  hover = false,
  striped = true,
  footer = false,
  sorter = false,
  pagination = false,
  keySetPagination = false,
  columnFilter = false,
  tableFilterValue,
  tableFilter = {
    label: i18n.t(`layout:table:filter`),
    placeholder: i18n.t(`layout:table:filter_placeholder`)
  },
  itemsPerPageSelect = {
    label: i18n.t(`layout:table:items_per_page`),
    values: [15, 30, 60]
  },
  noItemsView = {
    noResults: i18n.t(`layout:table:no_filtering_results`),
    noItems: i18n.t(`layout:table:no_items`)
  },
  scopedSlots,
  columnHeaderSlot,
  captionSlot,
  underTableSlot,
  overTableSlot,
  sortingIconSlot,
  theadTopSlot,
  filterProps,
  onRowClick,
  clickableRows,
  filterHandler,
  exportFunction
}: TableProps) => {
  // React Hook Form
  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm<TableFilter>({
    defaultValues: filterProps?.defaultValues
  });

  // Listen for key property while select field changes in filter
  // key-value options and detect the active option
  let filterKeyValueOption = filterProps?.keyValueOptions?.find(({ option }) => watch("key")?.value === option.value);

  // If "pagination" property is true it means custom pagination will be used
  // instead of CoreUI default pagination which is attached to the table.
  // Custom pagination has two buttons: <prev | next>
  const CustomPagination = () => {
    const exportBtn = !!exportFunction && (
      <CTooltip content={i18n.t("layout:buttons:export")}>
        <div>
          <CButton disabled={items.length === 1} color="light" onClick={exportFunction}>
            <Excel height="23" width="23" />
          </CButton>
        </div>
      </CTooltip>
    );

    return (
      <>
        {exportBtn}
        {keySetPagination && (
          <CButtonGroup className="pl-2">
            <CTooltip content={i18n.t("layout:buttons:prev")}>
              <CButton className="border border-secondary" color="light">
                <CIcon name="cil-chevron-left" />
              </CButton>
            </CTooltip>
            <CTooltip content={i18n.t("layout:buttons:next")}>
              <CButton className="border border-secondary" color="light">
                <CIcon name="cil-chevron-right" />
              </CButton>
            </CTooltip>
          </CButtonGroup>
        )}
      </>
    );
  };

  // One of the filter types is key value filter. We are using this filter when we want
  // add our custom filter properties. Key is basically select-option component
  // where we select table fields. Value is keyword, range or select-option value
  // which helps to identify what kind of data is searched
  const KeyValue = () => {
    return (
      <>
        {/*Key*/}
        <CCol md={3} className="pl-0">
          <Select
            inputMd={12}
            placeholder={i18n.t("layout:table:filter_key")}
            name="key"
            control={control}
            options={
              filterProps?.keyValueOptions
                ? filterProps?.keyValueOptions.map(({ option }) => option)
                : tableFields.map(({ key, label }) => {
                    return { value: key, label: label };
                  })
            }
          />
        </CCol>
        {/*Value, which can be either keyword, range or select-option field*/}
        <CCol md={5} className="pl-0">
          {!filterKeyValueOption?.type || filterKeyValueOption?.type === "keyword" ? (
            <Input control={control} name="keyword" inputMd={12} placeholder={i18n.t("layout:table:filter_value")} />
          ) : filterKeyValueOption?.type === "range" ? (
            <>
              <CRow className="p-0">
                <CCol className="pr-0">
                  <Input control={control} name="range.min" type="number" inputMd={12} placeholder={i18n.t("layout:table:min")} />
                </CCol>
                <CCol>
                  <Input type="number" control={control} name="range.max" inputMd={12} placeholder={i18n.t("layout:table:max")} />
                </CCol>
              </CRow>
            </>
          ) : (
            filterKeyValueOption?.type === "select" && (
              <Select
                inputMd={12}
                placeholder={i18n.t("layout:table:select_value")}
                name="select"
                control={control}
                options={filterKeyValueOption?.selectOptions}
              />
            )
          )}
        </CCol>
      </>
    );
  };

  // Another custom filter type is date range filter where by specifying
  // dates we can know what kind of date needed to be displayed.
  // TODO: define latest date as TODAY, note that FUTURE cannot be selected // DONE
  // TODO: start date must be <= to end date // DONE
  // TODO: apply input masks
  const DateRange = () => {
    return (
      <>
        <CCol md={4} className="pl-0">
          <Input
            type="date"
            control={control}
            name="date.start"
            max={dayjs(watch("date.end")).format("YYYY-MM-DD")}
            inputMd={12}
            placeholder={i18n.t("layout:table:start_date")}
          />
        </CCol>
        <CCol md={4} className="pl-0">
          <Input
            type="date"
            control={control}
            name="date.end"
            max={dayjs(Date.now()).format("YYYY-MM-DD")}
            inputMd={12}
            placeholder={i18n.t("layout:table:end_date")}
            onchange={(e) => {
              if (getValues("date.start") > e.target.value) setValue("date.start", e.target.value);
            }}
          />
        </CCol>
      </>
    );
  };

  // These buttons are responsible for approving or resetting filter data.
  // Note that we cannot reset date data if key value filter is displayed
  const buttons = (
    <CCol md={2} className="pl-0">
      <CTooltip content={i18n.t("layout:buttons:apply")} placement="top">
        <CButton color="success" className="mr-1" onClick={handleSubmit(filterHandler as SubmitHandler<any>)}>
          <CIcon name="cil-check-alt" />
        </CButton>
      </CTooltip>
      <CTooltip content={i18n.t("layout:buttons:clear")} placement="top">
        <CButton
          color="danger"
          onClick={() => {
            if (filterProps?.keyValue && filterProps?.dateRange) reset({ date: getValues("date") });
            else reset();
          }}
        >
          <CIcon name="cil-x" />
        </CButton>
      </CTooltip>
    </CCol>
  );

  // Table can be configured for various type of views. For example developer
  // can view table with custom key-set or default Core-UI pagination.
  // The same is true for filters. Custom filters can be viewed as all,
  // none, only key value filter or only date filter
  return (
    <Form>
      <CCardHeader className="px-0 pt-0">
        {filterProps?.dateRange && filterProps?.keyValue ? (
          <>
            <CRow className="px-3">
              <DateRange />
              <CCol md={2} />
            </CRow>
            <CRow className="px-3 justify-content-end">
              <KeyValue /> {buttons}
              <CCol md={2}>
                <CRow className="justify-content-end">
                  <CustomPagination />
                </CRow>
              </CCol>
            </CRow>
          </>
        ) : (
          <CRow className="px-3 justify-content-end">
            {filterProps?.dateRange ? (
              <>
                <DateRange />
                {buttons}
              </>
            ) : (
              filterProps?.keyValue && (
                <>
                  <KeyValue />
                  {buttons}
                </>
              )
            )}
            <CCol md={2}>
              <CRow className="justify-content-end">
                <CustomPagination />
              </CRow>
            </CCol>
          </CRow>
        )}
      </CCardHeader>
      <CDataTable
        addTableClasses={`${className} ${overflowHidden ? "overflowHidden" : ""}`}
        responsive={responsive}
        captionSlot={captionSlot}
        underTableSlot={underTableSlot}
        theadTopSlot={theadTopSlot}
        overTableSlot={overTableSlot}
        sortingIconSlot={sortingIconSlot}
        items={items}
        loading={loading}
        fields={tableFields}
        hover={hover}
        footer={footer}
        sorter={sorter}
        striped={striped}
        itemsPerPage={itemsPerPage}
        scopedSlots={scopedSlots}
        columnHeaderSlot={columnHeaderSlot}
        pagination={pagination}
        columnFilter={columnFilter}
        tableFilterValue={tableFilterValue}
        tableFilter={tableFilter}
        itemsPerPageSelect={itemsPerPageSelect}
        noItemsView={noItemsView}
        clickableRows={clickableRows}
        onRowClick={onRowClick}
      />
      {!pagination && (
        <CRow className="px-3 pt-2 justify-content-end">
          <CustomPagination />
        </CRow>
      )}
    </Form>
  );
};

export default Table;
