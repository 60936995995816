import { useState } from "react";
import getDisplayName from "./getDisplayName";
import Modal, { ModalProps } from "../Modal";

const withModal = (WrappedComponent: Function, id = "") => {
  const NewComponent = (props: any) => {
    const [modal, setModal] = useState<ModalProps>({ show: false });
    const handleClose = () => setModal({ ...modal, show: false });

    return (
      <>
        <Modal {...modal} onClose={modal.onClose || handleClose} modalId={id} />
        <WrappedComponent {...props} modal={modal} setModal={setModal} />
      </>
    );
  };

  NewComponent.displayName = `NewComponent(${getDisplayName(WrappedComponent)})`;

  return NewComponent;
};

export default withModal;
