export const modules = ["services", "partners", "about", "contact"];

// Login.tsx

export interface FormData {
  email: string;
  password: string;
}

export interface ILoginField {
  icon: string;
  name: string;
  type?: string;
}

export interface IView {
  name: string;
  next: State[];
  state: State;
  fields: ILoginField[];
}

export const enum State {
  None,
  Login,
  Register,
  ForgotPass,
  NewPass
}

export const views: IView[] = [
  {
    name: "login",
    next: [State.Register, State.ForgotPass],
    state: State.Login,
    fields: [
      { name: "phone", icon: "cil-phone" },
      { name: "password", icon: "cil-lock-locked" }
    ]
  },
  {
    name: "register",
    next: [State.Login, State.None],
    state: State.Register,
    fields: [
      { name: "firstName", icon: "cil-asterisk" },
      { name: "lastName", icon: "cil-asterisk" },
      { name: "middleName", icon: "cil-asterisk" },
      { name: "phone", icon: "cil-phone" },
      { name: "password", icon: "cil-lock-locked", type: "password" },
      { name: "repassword", icon: "cil-lock-locked", type: "password" }
    ]
  },
  {
    name: "forgotPass",
    next: [State.None, State.Login],
    state: State.ForgotPass,
    fields: [{ name: "phone", icon: "cil-phone" }]
  },
  {
    name: "newPass",
    next: [State.None, State.ForgotPass],
    state: State.NewPass,
    fields: [
      { name: "password", icon: "cil-lock-locked", type: "password" },
      { name: "repassword", icon: "cil-lock-locked", type: "password" },
      { name: "sms", icon: "cil-speech", type: "number" }
    ]
  }
];

export const LANDING = "landing";
