import { ApiService } from "./types";
import {
  GetAllPartnersResponse,
  GetPartnerResponse,
  GetAllPartnerPostsResponse,
  GetPartnerPostResponse,
  CreatePartnerPostRequest,
  CreatePartnerPostResponse,
  EditPartnerPostRequest,
  EditPartnerPostResponse,
  DeletePartnerPostResponse,
  EditPartnerLogoPostResponse
} from "./types/responseTypes/Partner";
import { IPartner } from "./types/interfaces";

export class PartnerService extends ApiService {
  async getAllPartners() {
    const res = await this.axios.get<GetAllPartnersResponse>("/partners", {});
    return res.data;
  }

  async getPartner(partnerUuid: string) {
    const res = await this.axios.get<GetPartnerResponse>(`/partners/${partnerUuid}`, {});
    return res.data;
  }

  async getAllPartnerPosts() {
    const res = await this.axios.get<GetAllPartnerPostsResponse>("/partners/posts", {});
    return res.data as IPartner[];
  }

  async getPartnerPost(partnerUuid: string) {
    const res = await this.axios.get<GetPartnerPostResponse>(`/partners/${partnerUuid}/post`, {});
    return res.data as any;
  }

  async createPartnerPost(formData: FormData) {
    const res = await this.axios.post<CreatePartnerPostResponse>("/partners/posts", formData, undefined, {
      "Content-Type": "multipart/form-data"
    });
    return res.data;
  }

  async editPartnerPost(partnerUuid: string, params: EditPartnerPostRequest) {
    const res = await this.axios.put<EditPartnerPostResponse>(`/partners/${partnerUuid}/post`, params);
    return res.data;
  }

  async editPartnerLogoPost(partnerUuid: string, formData: FormData) {
    const res = await this.axios.post<EditPartnerLogoPostResponse>(
      `/partners/${partnerUuid}/post/images/logo`,
      formData,
      undefined,
      {
        "Content-Type": "multipart/form-data"
      }
    );
    return res.data;
  }

  async deletePartnerPost(partnerUuid: string) {
    const res = await this.axios.delete<DeletePartnerPostResponse>(`/partners/${partnerUuid}/post`, {});
    return res.data;
  }
}
