import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Login from "./Login";
import withModal from "../../containers/UIElements/HOCs/withModal";
import { LANDING, modules } from "../constants";
import { LanguageSelect } from "../../containers/UIElements";
import ForsetiLogo from "../../assets/icons/forseti-logo.svg";
import { useClientAuth, useUpdateClientAuth } from "../../AuthContext";
import { CSpinner } from "@coreui/react";
import ServiceFactory from "../../utils";

const componentName = "landingPage:navbar";
const api = ServiceFactory.getAuthService();
const Navigation = (props) => {
  const { t } = useTranslation();
  const { modal, setModal } = props;
  const { client, isFetchingClient } = useClientAuth();
  const updateAuth = useUpdateClientAuth();

  const handleLogout = () => {
    api.logoutClient().finally(() => updateAuth());
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="py-0">
      <Container>
        <Navbar.Brand href="/#">
          <img src={ForsetiLogo} alt="logo" style={{ height: "2.9rem" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
          <Nav className="me-auto">
            {modules.map((tab: string, index: number) => {
              return (
                <Nav.Link key={index} href={`/#${tab}`} className="text-capitalize">
                  {t(`${componentName}:${tab}`)}
                </Nav.Link>
              );
            })}
          </Nav>
          <Navbar.Text id="name-lang-wrapper">
            {/* {client ? (
              <Navbar.Text id="username-dropdown">
                <NavDropdown title={client.first_name + " " + client.last_name} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={handleLogout}>{t(`layout:header:logout`)}</NavDropdown.Item>
                </NavDropdown>
              </Navbar.Text>
            ) : isFetchingClient ? (
              <CSpinner size="sm" />
            ) : (
              <a
                id="login-modal"
                className="btn-login"
                onClick={() => setModal({ ...modal, show: true, title: <></>, body: <Login {...props} /> })}
              >
                {t(`login:title`)}
              </a>
            )} */}
            <Navbar.Text>
              <LanguageSelect />
            </Navbar.Text>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withModal(Navigation, LANDING);
