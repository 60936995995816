import React, { useMemo } from "react";
import {
  CCreateElement,
  CSidebar,
  // CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem
} from "@coreui/react";

//import logoResized from "../assets/images/logo-resized.png";

// sidebar nav config
import navigation from "../config/sidebar_route.config";
import { useTranslation } from "react-i18next";

const TheSidebar = ({ sidebar, setSidebar }) => {
  const { t } = useTranslation();
  const show = sidebar.sidebarShow;

  // const filterRoutes = () => {
  //   let filteredRoutes = navigation(t);
  //   filteredRoutes = filteredRoutes
  //       // Iterate over routes and filter routes from children of top level route
  //       // Structure of route is bus -> [add_bus, get_buses...]
  //       .map((a) => {
  //         if (typeof a._children !== "undefined" && Array.isArray(a._children)) {
  //           a._children = a._children.filter((child) =>
  //               typeof child.permission === "undefined"
  //                   ? true
  //                   : user.permissions?.includes(child.permission)
  //           );
  //           // if route has no child set it to undefined to be removed later
  //           return a._children.length === 0 ? undefined : a;
  //         }
  //
  //         // if route has permission check if it is valid, otherwise if there is no permission show it
  //         return user.permissions?.includes(a.permission)? a : (typeof a.permission === "undefined"? a : undefined);
  //       })
  //       // Filter top level routes which has no child
  //       .filter((i) => typeof i !== "undefined");
  //   return filteredRoutes;
  // };

  const filteredNavigation = useMemo(/*filterRoutes*/ () => navigation(t), [t /*, user*/]);
  return (
    <CSidebar show={show} onShowChange={(val) => setSidebar({ sidebarShow: val })}>
      {/*<CSidebarBrand*/}
      {/*    style={{ backgroundColor: "#E3E3E3" }}*/}
      {/*    className="d-md-down-none"*/}
      {/*    to="/"*/}
      {/*>*/}
      {/*  /!* <CIcon*/}
      {/*  className="c-sidebar-brand-full"*/}
      {/*  name="logo-negative"*/}
      {/*  height={35}*/}
      {/*/> *!/*/}
      {/*  <img src={logoResized} height={35} alt="Logo" />*/}
      {/*  /!* <CIcon*/}
      {/*  className="c-sidebar-brand-minimized"*/}
      {/*  name="sygnet"*/}
      {/*  height={35}*/}
      {/*/> *!/*/}
      {/*</CSidebarBrand>*/}
      <CSidebarNav>
        <CCreateElement
          items={filteredNavigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
