import { CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React from "react";

const AlertView = ({ title, message, iconName, color }) => {
  return (
    <CRow>
      <CCol md={2}>
        <CIcon className={`mt-1 text-${color}`} height={35} width={35} name={iconName} />
      </CCol>
      <CCol md={10}>
        <h5 className={`text-${color} ${!message ? "pl-2 pt-2 mt-1" : ""}`}>{title}</h5>
        {message && <h6 className="mb-0 text-dark">{message}</h6>}
      </CCol>
    </CRow>
  );
};

export default AlertView;
