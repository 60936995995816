import { toast } from "react-toastify";
import React from "react";
import AlertView from "./AlertView";

const DangerHandler = (
  title: string,
  message?: string,
  iconName: string = "cil-x-circle"
) => {
  toast(
    <AlertView
      title={title}
      message={message}
      iconName={iconName}
      color="danger"
    />,
    {
      type: "error",
    }
  );
};

export default DangerHandler;
