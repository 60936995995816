import { Controller } from "react-hook-form";
import { CCol, CFormGroup, CInvalidFeedback, CLabel } from "@coreui/react";
import ReactSelect from "react-select";
import React from "react";
import { SelectProps } from "./types";

//TODO: fix validation in select
function Select({
  placeholder,
  control,
  name,
  label,
  options,
  size = "md",
  labelMd = 4,
  inputXs = 12,
  inputMd = 8,
  disabled = false,
  required,
  isLoading = false,
  pattern,
  validate
}: SelectProps) {
  return (
    <Controller
      name={name}
      rules={{
        validate: validate?.value,
        required: required && {
          value: true,
          message: required
        },
        pattern: pattern && {
          value: pattern.value,
          message: pattern.message
        }
      }}
      control={control}
      render={({ field: { ref, ...fields }, fieldState }) => {
        const invalid = (
          <CInvalidFeedback>
            {fieldState.error?.type === "validate" ? validate?.message : fieldState.error?.message}
          </CInvalidFeedback>
        );

        return (
          <CFormGroup row>
            {label && (
              <CCol md={labelMd}>
                <CLabel htmlFor={`hf-${name}`} className="font-weight-bold">
                  {label}
                </CLabel>
              </CCol>
            )}
            <CCol xs={inputXs} md={inputMd}>
              <ReactSelect
                isDisabled={disabled}
                // NOTE: "size" and "invalid" props are removed in react-select@v5.
                // size={size}
                // invalid={fieldState.invalid}
                placeholder={placeholder}
                options={options}
                isLoading={isLoading}
                // NOTE: Replaced removed "innerRef" prop with new "ref" prop.
                // Check again before extending this component.
                // If everything is fine, remove this note.
                ref={ref}
                {...fields}
              />
              {/*TODO: fix invalid select, not working properly*/}
              {invalid}
            </CCol>
          </CFormGroup>
        );
      }}
    />
  );
}

export default Select;
