import {
  CCard,
  CCardBody,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React from "react";

const Tabs = ({ activeTab, tabs }) => {
  return (
    <CTabs activeTab={activeTab}>
      <CNav variant="tabs">
        {tabs.map(({ value, label }) => {
          return (
            <CNavItem key={value}>
              <CNavLink data-tab={value}>{label}</CNavLink>
            </CNavItem>
          );
        })}
      </CNav>
      <CTabContent>
        {tabs.map(({ value, content }) => {
          return (
            <CTabPane key={`${value}-content`} data-tab={value}>
              <CCard style={{ borderTopLeftRadius: "0" }}>
                <CCardBody>{content}</CCardBody>
              </CCard>
            </CTabPane>
          );
        })}
      </CTabContent>
    </CTabs>
  );
};

export default Tabs;
