import { ApiService } from "../../utils/api/types";

const root = "/services/";
class LandingPageService extends ApiService {
  public async getCustomers() {
    const res = await this.axios.get<any>(`customers`, {});

    console.log("res: ", res);
    return res.data;
  }

  async getAllServices(cityId: number, categoryId: number) {
    const res = await this.axios.get<any>(root, { city_id: cityId, type_id: categoryId });
    return res.data as any[];
  }

  async getService(uuid: string) {
    const res = await this.axios.get<any>(root + uuid, {});
    return res.data as any[];
  }

  async useService(uuid: string, secret: string) {
    const res = await this.axios.post<any>(root + uuid + "/usage", { secret });
    return res.data as any[];
  }

  async getSuggestions(uuid: string) {
    const res = await this.axios.get<any>(root + uuid + "/related", {});
    return res.data as any[];
  }
}

export default LandingPageService;
