import { Controller } from "react-hook-form";
import {
  CCol,
  CFormGroup,
  CInvalidFeedback,
  CLabel,
  CTextarea,
} from "@coreui/react";
import { InputProps } from "./types";

function Textarea({
  control,
  name,
  placeholder,
  required,
  limits,
  label,
  htmlFor,
  size,
  labelMd = 4,
  inputXs = 12,
  inputMd = 8,
  disabled,
  autoComplete,
  defaultValue,
  pattern,
  validate,
  rows = 3,
}: InputProps) {
  return (
    <Controller
      name={name}
      rules={{
        validate: validate?.value,
        required: required && {
          value: true,
          message: required,
        },
        minLength: limits?.min,
        maxLength: limits?.max,
        pattern: pattern && {
          value: pattern.value,
          message: pattern.message,
        },
      }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState }) => {
        const textarea = (
          <CTextarea
            rows={rows}
            value={value ?? ""}
            disabled={disabled}
            id={htmlFor}
            invalid={fieldState.invalid}
            size={size}
            placeholder={placeholder}
            innerRef={ref}
            autoComplete={autoComplete}
            {...field}
          />
        );

        const invalid = (
          <CInvalidFeedback>
            {fieldState.error?.type === "validate"
              ? validate?.message
              : fieldState.error?.message}
          </CInvalidFeedback>
        );

        return (
          <CFormGroup row>
            {label && (
              <CCol md={labelMd}>
                <CLabel htmlFor={htmlFor} className="font-weight-bold">
                  {label}
                </CLabel>
              </CCol>
            )}
            <CCol xs={inputXs} md={inputMd}>
              <>
                {textarea}
                {invalid}
              </>
            </CCol>
          </CFormGroup>
        );
      }}
    />
  );
}

export default Textarea;
