import { CCol, CLabel, CSwitch, CFormGroup } from "@coreui/react";
import { SwitchProps } from "./types";
import { Controller } from "react-hook-form";

function Switch({
  control,
  defaultValue,
  disabled = false,
  label,
  labelMd = 4,
  htmlFor,
  switchXs = 12,
  switchMd = 8,
  color = "dark",
  className = "mx-1",
  name,
}: SwitchProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, value, ...field } }) => {
        return (
          <CFormGroup row>
            {label && (
              <CCol md={labelMd}>
                <CLabel htmlFor={htmlFor} className="font-weight-bold">
                  {label}
                </CLabel>
              </CCol>
            )}
            <CCol xs={switchXs} md={switchMd}>
              <CSwitch
                innerRef={ref}
                disabled={disabled}
                className={className}
                color={color}
                labelOn={"\u2713"}
                labelOff={"\u2715"}
                checked={value}
                {...field}
              />
            </CCol>
          </CFormGroup>
        );
      }}
    />
  );
}

export default Switch;
