import { CheckboxProps } from "./types";
import { Controller } from "react-hook-form";
import { CCol, CLabel, CFormGroup, CInputCheckbox } from "@coreui/react";

function Checkbox({
  control,
  defaultValue,
  disabled = false,
  label,
  labelMd = 4,
  htmlFor,
  checkboxXs = 12,
  checkboxMd = 8,
  className = "mx-1",
  name,
  onValueChange,
}: CheckboxProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, value, onChange, ...field } }) => {
        return (
          <CFormGroup row>
            {label && (
              <CCol md={labelMd}>
                <CLabel htmlFor={htmlFor} className="font-weight-bold">
                  {label}
                </CLabel>
              </CCol>
            )}
            <CCol xs={checkboxXs} md={checkboxMd}>
              <CInputCheckbox
                innerRef={ref}
                disabled={disabled}
                className={className}
                checked={value}
                onChange={(e) => {
                  onChange(e);
                  onValueChange();
                }}
                {...field}
              />
            </CCol>
          </CFormGroup>
        );
      }}
    />
  );
}

export default Checkbox;
