import { TFunction } from "i18next";

const prod_routes = (t: TFunction) =>
  [
    {
      _tag: "CSidebarNavItem",
      name: t("layout:sidebar:dashboard"),
      to: "/admin",
      icon: "cil-chart"
    },
    {
      _tag: "CSidebarNavItem",
      name: t("layout:sidebar:services"),
      to: "/admin/services",
      icon: "cil-window-restore"
    },
    {
      _tag: "CSidebarNavItem",
      name: t("layout:sidebar:clients"),
      to: "/admin/clients",
      icon: "cil-people"
    },
    {
      _tag: "CSidebarNavItem",
      name: t("layout:sidebar:partners"),
      to: "/admin/partners",
      icon: "cil-people"
    }
  ] as any[];

function Sidebar(t: TFunction) {
  let res = prod_routes(t);
  return res;
}

export default Sidebar;
