import { useEffect, useState } from "react";
import { CRow, CCol } from "@coreui/react";
import Select, { components } from "react-select";
import { customStyles } from "../../containers/UIElements/constants";
import { useTranslation } from "react-i18next";
import { CityList, CategoryList, countries, categories, localeCompare } from "../../utils/constants";
import CIcon from "@coreui/icons-react";

const componentName = "landingPage:services";
const Filter = (props) => {
  const { t, i18n } = useTranslation();
  const [service, setService] = useState<Category>(categories[0]);
  // default country is Azerbaijan
  const [country, setCountry] = useState<Country>(countries.find(({ id }) => id === 994)!);
  // default city is Baku
  const [city, setCity] = useState<City>(country.cities.find(({ id }) => id === 99412)!);
  // sort location options alphabetically by selected language
  const locations = [
    { location: "countries", value: country, options: countries.sort(localeCompare(t, "countries")) },
    { location: "cities", value: city, options: country.cities.sort(localeCompare(t, "cities")) }
  ];
  // sort medicine options alphabetically by selected language
  const doctors: Doctor[] = categories
    .find(({ name }) => name === "medicine")
    ?.subcategories.sort(localeCompare(t, "medicine", "name"))!;
  // default doctor alphabetically first one in the list
  const [doctor, setDoctor] = useState<Doctor>(doctors[0]);

  useEffect(() => {
    handleClick();
  }, []);

  const handleLocationChange = (data) => {
    // if data is type of country set both new country and new default city
    if (data.cities) {
      setCountry(data);
      setCity(data?.cities[0]);
    } else setCity(data);
  };

  const handleClick = () => {
    props.fetch({ city, category: service.name === "medicine" ? doctor : service });
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t(`${componentName}:title`)}</h2>
          {/*<p>{t(`${componentName}:subtitle`)}</p>*/}
        </div>
        {[
          { start: 0, end: 3 },
          { start: 3, end: 6 }
        ].map(({ start, end }, key: number) => (
          <CRow key={key} className="nowrap">
            {categories.slice(start, end).map(({ icon, name }, index: number) => (
              <CCol md={4} key={`${name}-${index}`}>
                <i
                  className={icon + (name === service.name ? " checked" : "")}
                  onClick={() =>
                    name !== service.name &&
                    setService(categories.find((category) => name === category.name) as Category)
                  }
                />
                <div className="service-desc">
                  <h3>{t(`${componentName}:${name}`)}</h3>
                </div>
              </CCol>
            ))}
          </CRow>
        ))}
        <CRow className="justify-content-center p-0 m-0 mt-3 service-filters">
          {locations.map(({ location, value, options }) => (
            <CCol lg={service.name === "medicine" ? 3 : 5} className="p-3" key={location}>
              <Select
                getOptionLabel={({ id }) => t(`${location}:${id}`)}
                getOptionValue={({ id }) => `${id}`}
                components={{ NoOptionsMessage }}
                className="select-custom btn-lg"
                styles={customStyles}
                value={value}
                placeholder={t(`services:placeholders:${location}`)}
                onChange={handleLocationChange}
                options={options}
              />
            </CCol>
          ))}
          {service.name === "medicine" && (
            <CCol lg={4} className="p-3">
              <Select
                getOptionLabel={({ name }) => t(`medicine:${name}`)}
                getOptionValue={({ id }) => `${id}`}
                components={{ NoOptionsMessage }}
                className="select-custom btn-lg"
                styles={customStyles}
                value={doctor}
                placeholder={t(`services:placeholders:medicine`)}
                onChange={(data) => setDoctor(data as Doctor)}
                options={doctors}
              />
            </CCol>
          )}
          <CCol lg={2} className="p-3">
            <a
              href="#partners"
              className="btn btn-custom btn-lg page-scroll"
              style={{ width: "100%" }}
              onClick={handleClick}
            >
              <CIcon icon="cil-arrow-right" height={20} />
            </a>
          </CCol>
        </CRow>
      </div>
    </div>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Text</span>
    </components.NoOptionsMessage>
  );
};

type Country = {
  id: number;
  name: string;
  cities: CityList;
};

type City = {
  id: number;
  name: string;
};

type Doctor = {
  id: number;
  name: string;
};

type Category = {
  id: number;
  name: string;
  subcategory?: CategoryList;
};

export default Filter;
