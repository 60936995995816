import React, { Suspense, lazy } from "react";
import { CContainer } from "@coreui/react";
import { useRoutes } from "react-router-dom";
const Clients = lazy(() => import("../admin/clients/Clients"));
const Partners = lazy(() => import("../admin/partners/Partners"));
const Partner = lazy(() => import("../admin/partners/Partner"));
const Services = lazy(() => import("../admin/services/Services"));
const Service = lazy(() => import("../admin/services/Service"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const TheContent = () => {
  const adminRoutes = useRoutes([
    {
      path: "clients",
      element: <Suspense fallback={loading} children={<Clients />} />
    },
    {
      path: "partners",
      element: <Suspense fallback={loading} children={<Partners />} />
    },
    {
      path: "services",
      element: <Suspense fallback={loading} children={<Services />} />
    },
    {
      path: "services/:uuid",
      element: <Suspense fallback={loading} children={<Service />} />
    },
    {
      path: "partners/:uuid",
      element: <Suspense fallback={loading} children={<Partner />} />
    }
  ]);

  return (
    <main className="c-main">
      <CContainer fluid>{adminRoutes}</CContainer>
    </main>
  );
};

export default React.memo(TheContent);
