import { Controller } from "react-hook-form";
import { CCol, CFormGroup, CInput, CInputGroup, CInputGroupAppend, CInputGroupPrepend, CInputGroupText, CInvalidFeedback, CLabel } from "@coreui/react";
import { InputProps } from "./types";

function Input({
  control,
  name,
  placeholder,
  required,
  limits,
  label,
  htmlFor,
  size,
  type = "text",
  labelMd = 4,
  inputXs = 12,
  inputMd = 8,
  step,
  disabled,
  autoComplete,
  prepend,
  append,
  appendButton,
  defaultValue,
  pattern,
  validate,
  max,
  min,
  onchange
}: InputProps) {
  return (
    <Controller
      name={name}
      rules={{
        validate: validate?.value,
        required: required && {
          value: true,
          message: required
        },
        minLength: limits?.min,
        maxLength: limits?.max,
        pattern: pattern && {
          value: pattern.value,
          message: pattern.message
        }
      }}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, value, onChange, ...field }, fieldState }) => {
        const input = (
          <CInput
            max={max}
            min={min}
            value={value ?? ""}
            disabled={disabled}
            id={htmlFor}
            invalid={fieldState.invalid}
            size={size}
            step={step}
            type={type}
            placeholder={placeholder}
            innerRef={ref}
            autoComplete={autoComplete}
            onChange={(e) => {
              if (!!onchange) onchange(e);
              onChange(e);
            }}
            {...field}
          />
        );

        const invalid = <CInvalidFeedback>{fieldState.error?.type === "validate" ? validate?.message : fieldState.error?.message}</CInvalidFeedback>;

        return (
          <CFormGroup row>
            {label && (
              <CCol md={labelMd}>
                <CLabel htmlFor={htmlFor} className="font-weight-bold">
                  {label}
                </CLabel>
              </CCol>
            )}
            <CCol xs={inputXs} md={inputMd}>
              {append || prepend || appendButton ? (
                <>
                  <CInputGroup>
                    {prepend && (
                      <CInputGroupPrepend>
                        <CInputGroupText className="rounded-left">{prepend}</CInputGroupText>
                      </CInputGroupPrepend>
                    )}
                    {input}
                    {append && (
                      <CInputGroupAppend>
                        <CInputGroupText className="rounded-right">{append}</CInputGroupText>
                      </CInputGroupAppend>
                    )}
                    {appendButton && <CInputGroupAppend>{appendButton}</CInputGroupAppend>}
                  </CInputGroup>
                  {/*TODO: invalid error view does not show when there is append or prepend*/}
                  {invalid}
                </>
              ) : (
                <>
                  {input}
                  {invalid}
                </>
              )}
            </CCol>
          </CFormGroup>
        );
      }}
    />
  );
}

export default Input;
