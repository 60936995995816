import i18n from "../translate";
import { ApiService } from "./types";
import {
  ChangePasswordRequest,
  ClientSession,
  GetEmployeeSessionResponse,
  LoginClientResponse,
  LoginEmployeeResponse,
  ProfileEditRequest,
  ResetClientPasswordRequest,
  ResetEmployeePasswordRequest,
  SendResetPasswordTokenToClientRequest,
  SendResetPasswordTokenToEmployeeRequest
} from "./types/responseTypes/Auth";
import { IEmployee, IClient } from "./types/interfaces";

class AuthService extends ApiService {
  public async loginEmployee({
    phoneNumber,
    password
  }: {
    phoneNumber: string;
    password: string;
  }): Promise<LoginEmployeeResponse> {
    const res = await this.axios.post<LoginEmployeeResponse>("/employees/login", {
      phone_number: phoneNumber,
      password
    });
    return res.data;
  }

  public async loginClient({
    phoneNumber,
    password
  }: {
    phoneNumber: string;
    password: string;
  }): Promise<LoginClientResponse> {
    const res = await this.axios.post<LoginClientResponse>("/clients/login", {
      phone_number: phoneNumber,
      password
    });
    return res.data;
  }

  public async sendResetPasswordTokenToEmployee(body: SendResetPasswordTokenToEmployeeRequest): Promise<void> {
    await this.axios.post("/employees/me/reset-password/send-token", body);
  }

  public async resetEmployeePassword(body: ResetEmployeePasswordRequest): Promise<void> {
    await this.axios.post("/employees/me/reset-password", body);
  }

  public async sendResetPasswordTokenToClient(body: SendResetPasswordTokenToClientRequest): Promise<void> {
    await this.axios.post("/clients/me/reset-password/send-token", body);
  }

  public async resetClientPassword(body: ResetClientPasswordRequest): Promise<void> {
    await this.axios.post("/clients/me/reset-password", body);
  }

  public async changeEmployeePassword({ oldPassword, newPassword }: ChangePasswordRequest) {
    await this.axios.post("/employees/me/change-password", {
      old_password: oldPassword,
      new_password: newPassword
    });
  }

  public async changeClientPassword({ oldPassword, newPassword }: ChangePasswordRequest) {
    await this.axios.post("/clients/me/change-password", {
      old_password: oldPassword,
      new_password: newPassword
    });
  }

  public async editProfile({ DOB, firstName, lastName }: ProfileEditRequest) {
    await this.axios.patch("/employees/profile/edit", {
      dob: DOB,
      first_name: firstName,
      last_name: lastName
    });
  }

  public async logoutEmployee(): Promise<void> {
    await this.axios.post("/employees/logout", {});
    localStorage.removeItem("auth_token");
  }

  public async logoutClient(): Promise<void> {
    await this.axios.post("/clients/logout", {});
    localStorage.removeItem("auth_token");
  }

  public async startupChecks() {
    this.retrieveLanguage();
    return {};
  }

  public async checkEmployeeSession(): Promise<GetEmployeeSessionResponse> {
    const { data } = await this.axios.get<GetEmployeeSessionResponse>("/employees/me", {});
    return data;
  }

  public async checkClientSession(): Promise<ClientSession> {
    const { data } = await this.axios.get<ClientSession>("/clients/me", {});
    return data;
  }

  public setLanguage(lang?: string) {
    if (lang != null) {
      localStorage.setItem("lang", lang);
      i18n.changeLanguage(lang);
    }
  }

  private retrieveLanguage() {
    const lang = localStorage.getItem("lang");
    if (lang != null) {
      i18n.changeLanguage(lang);
    }
  }
}
export default AuthService;
