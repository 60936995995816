import React from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";

function SinglePageButton({ onClick }) {
  return (
    <CButton onClick={onClick}>
      <CIcon icon="cil-arrow-right" height={30} />
    </CButton>
  );
}

export default SinglePageButton;
