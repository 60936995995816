import dayjs from "dayjs";
import CustomAxios from "../Axios";

export abstract class ApiService {
  protected axios: CustomAxios = new CustomAxios();

  public ISOToDate = (ISODate: string) => {
    return dayjs(ISODate).format("DD-MM-YYYY HH:MM");
  };
}

export enum ServiceTypes {
  AuthService,
  ReduxService,
  SupportService
}

export interface SuccessResponse {
  status: "success";
}

export interface ErrorResponse {
  status: "fail" | "auth_fail" | "internal_fail";
}

export enum SchemaType {
  Add,
  Edit
}
