import { CForm } from "@coreui/react";
import React from "react";

const Form = ({ children }) => {
  return (
    <CForm
      onReset={(e) => {
        e.preventDefault();
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </CForm>
  );
};

export default Form;
