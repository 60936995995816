import React from "react";
import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

export type ModalProps = {
  show: boolean;
  bodyId?: string;
  modalId?: string;
  onClose?: Function;
  title?: any;
  body?: any;
  footer?: any;
  color?: string;
  size?: "" | "sm" | "lg" | "xl";
};

const Modal = ({
  show,
  onClose,
  title = "",
  body,
  footer,
  color = "info",
  size = "",
  bodyId = "",
  modalId = ""
}: ModalProps) => {
  return (
    <CModal
      show={show}
      onClose={onClose}
      color={color}
      size={size as "" | "sm" | "lg" | "xl"}
      id={modalId}
    >
      {title && (
        <CModalHeader closeButton>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
      )}
      {body && <CModalBody id={bodyId}>{body}</CModalBody>}
      {footer && <CModalFooter>{footer}</CModalFooter>}
    </CModal>
  );
};

export default Modal;
