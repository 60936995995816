import React, { useEffect, useState } from "react";
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor";
import { CCol, CFormGroup, CLabel, CInput, CRow, CButton, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Modal from "./Modal";
import { t } from "i18next";
import { GalleryImage } from "./types";

function ImageEditor({
  label,
  labelMd = 4,
  inputXs = 12,
  inputMd = 8,
  gallery,
  setGallery,
  single = false,
  limit = 7
}) {
  const [imgSrc, setImgSrc] = useState("");
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  useEffect(() => {
    gallery.forEach((img: any, index) => {
      const image = new Image();

      const canvas = document.getElementById("canvas-" + index) as HTMLCanvasElement;
      const ctx = canvas?.getContext("2d");

      image.onload = function () {
        canvas.width = Math.floor(image.naturalWidth);
        canvas.height = Math.floor(image.naturalHeight);

        ctx?.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
      };
      image.src = img.previewUrl;
    });
  }, [gallery]);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
      openImgEditor();
    }
  }

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  return (
    <CFormGroup row>
      {label && (
        <CCol md={labelMd}>
          <CLabel className="font-weight-bold">{label}</CLabel>
        </CCol>
      )}
      <CCol xs={inputXs} md={inputMd}>
        <Modal
          // title={label}
          show={isImgEditorShown}
          onClose={closeImgEditor}
          body={
            imgSrc.length > 0 ? (
              <FilerobotImageEditor
                source={imgSrc}
                defaultSavedImageName="image"
                defaultSavedImageType="jpeg"
                onSave={(editedImageObject, designState) => {
                  // save new img otherwise update
                  const newImage: GalleryImage = {
                    originalUrl: designState.imgSrc,
                    previewUrl: editedImageObject.imageBase64,
                    canvas: editedImageObject.imageCanvas
                  } as GalleryImage;

                  setGallery(
                    single
                      ? [newImage]
                      : [
                          ...gallery.filter((img: any) => img.originalUrl !== designState.imgSrc),
                          newImage
                        ]
                  );

                  closeImgEditor();
                }}
                // Rotate={{ componentType: "buttons" }}
                Crop={{
                  ratio: 1,
                  noPresets: true,
                  autoResize: true
                }}
                // Rotate={{ angle: 90, componentType: "slider" }}
                Image={{ gallery }}
                tabsIds={[TABS.FINETUNE, TABS.ADJUST]}
                defaultTabId={TABS.ADJUST}
                defaultToolId={TOOLS.CROP}
                savingPixelRatio={2}
                previewPixelRatio={window.devicePixelRatio}
              />
            ) : null
          }
        />
        {gallery.length < limit && (
          <div
            style={{
              height: "6rem",
              border: "1px dashed #CBD5E0",
              borderRadius: "0.25rem",
              display: "flex",
              alignItems: "center"
            }}
          >
            <CRow className="justify-content-center" style={{ margin: "0 auto", width: "0" }}>
              <CRow>
                <CIcon icon="cil-image-plus" height={40} style={{ color: "#d8dbe0" }} />
              </CRow>
              <CLabel
                htmlFor={"image-editor"}
                className="font-weight-bold justify-content-center"
                style={{ whiteSpace: "nowrap", cursor: "pointer" }}
              >
                Click to upload a file
              </CLabel>
            </CRow>

            <CInput
              value={""}
              style={{ visibility: "hidden", position: "fixed" }}
              id={"image-editor"}
              type="file"
              accept="image/*"
              onChange={(e: any) => {
                onSelectFile(e);
              }}
            />
          </div>
        )}

        {gallery.map((img, index) => {
          return (
            <CRow style={{ padding: "10px 0" }}>
              <canvas
                id={"canvas-" + index}
                style={{
                  position: "relative",
                  visibility: "visible",
                  border: "1px solid black",
                  objectFit: "contain",
                  width: "300px",
                  height: "300px"
                }}
              />
              <CTooltip content={t("layout:buttons:clear")} placement="top">
                <CButton
                  color="danger"
                  onClick={() => setGallery(gallery.filter((img, i) => i !== index))}
                >
                  <CIcon icon="cil-x" height={20} />
                </CButton>
              </CTooltip>
            </CRow>
          );
        })}
        {/* <button onClick={openImgEditor}>Open Filerobot image editor</button> */}
      </CCol>
    </CFormGroup>
  );
}

export default ImageEditor;
