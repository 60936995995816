import { CRow } from "@coreui/react";
const RankingStars = ({ points }) => {
  return (
    <CRow className="m-0 py-1">
      <i className="fa fa-star" />
      <i className="fa fa-star-half-o" />
      <i className="fa fa-star-o" />
      <i className="fa fa-star-o" />
      <i className="fa fa-star-o" />
    </CRow>
  );
};
export default RankingStars;
