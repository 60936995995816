import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CRow, CCol } from "@coreui/react";
import { ServiceCard } from "../../containers/UIElements";
import { getTranslation } from "../../utils/translate";
import { withTranslation } from "react-i18next";
import getInstances from "../../utils";

const api = getInstances.getLandingPageService();
const Suggestions = ({ uuid, t, i18n: { language } }) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);

  useEffect(() => {
    api
      .getSuggestions(uuid)
      .then((data) => setSuggestions(data))
      .catch((err) => console.log(err));
  }, []);

  return suggestions && suggestions.length > 0 ? (
    <Container>
      <CRow>
        <h2 className="partner-page-title pl-3 partner">{t("landingPage:suggestions:title")}</h2>
      </CRow>
      <CRow>
        {suggestions.map(({ uuid, thumbnail_image_url, ...rest }) => (
          <CCol md={3} key={uuid}>
            <ServiceCard
              uuid={uuid}
              imgSrc={thumbnail_image_url}
              title={getTranslation(language, "title", rest)}
              text={getTranslation(language, "short_desc", rest)}
              partnerName={getTranslation(language, "partner_name", rest)}
            />
          </CCol>
        ))}
      </CRow>
    </Container>
  ) : null;
};

export default withTranslation()(Suggestions);
