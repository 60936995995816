import { useState } from "react";
import { CCol } from "@coreui/react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

interface ILanguage {
  countryCode: string;
  position: number;
}
/**
 * In order to add new language, it will be
 * enough to pass countryCode to the array.
 * In order to increase number of flags (default = 4),
 * change $max-of-langs variable in /src/scss/landing.scss
 * */

const COUNTRY_CODES = Object.freeze<string[]>(["az", "gb", "ru"]);
const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [languages, setLanguages] = useState<ILanguage[]>(
    COUNTRY_CODES.map((code: string, index: number) => ({ countryCode: code, position: ++index })) as ILanguage[]
  );

  const onLanguageSelect = (countryCode: string) => {
    if (open) {
      // flag position 1 is reserved for selected language
      let count = 2;
      // reposition all country flags
      setLanguages(
        languages.map((language: ILanguage) => ({
          ...language,
          position: countryCode === language.countryCode ? 1 : count++
        }))
      );
      i18n.changeLanguage(countryCode);
    }
    setOpen(!open);
  };

  const getFlagStyles = (position: number) => {
    return {
      // selected flag always has greatest z-index value
      zIndex: languages.length - position + 1,
      // on close decrease scale of the flags that are not
      // selected in order to avoid flags border/edge overlaping.
      transform: open ? "" : position !== 1 ? "scale(.2)" : "none"
    };
  };

  return (
    <CCol>
      {languages.map(({ countryCode, position }: ILanguage) => (
        <ReactCountryFlag
          key={countryCode}
          className={`flag translate${position}`}
          style={getFlagStyles(position)}
          onClick={() => onLanguageSelect(countryCode)}
          countryCode={countryCode}
          svg
        />
      ))}
    </CCol>
  );
};

export default LanguageSelect;
