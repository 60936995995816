import { CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import withModal from "../../containers/UIElements/HOCs/withModal";
import SocialShare from "../../containers/UIElements/SocialShare";
import { LANDING } from "../constants";

const componentName = "landingPage:header";
const Header = ({ modal, setModal }) => {
  const { t } = useTranslation();

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <CRow className="justify-content-center">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>{t(`${componentName}:title`)}</h1>
                <p>{t(`${componentName}:subtitle`)}</p>
                <a
                  className="btn btn-custom btn-lg page-scroll"
                  onClick={() => {
                    setModal({
                      ...modal,
                      show: true,
                      title: <></>,
                      body: (
                        <SocialShare shareUrl="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice" />
                      )
                    });
                  }}
                >
                  {t(`${componentName}:share`)}
                </a>
              </div>
            </CRow>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withModal(Header, LANDING);
