import { useState } from "react";
import { CButton, CCard, CCardBody, CCardGroup, CCol, CForm, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import ServiceFactory from "../../utils";
import { useForm } from "react-hook-form";
import { Input, Select } from "../../containers/UIElements";
import useCountDown from "react-countdown-hook";
import { IView, State, views } from "../constants";
import { useUpdateClientAuth } from "../../AuthContext";

interface FormData {
  phone: string;
  password: string;
}

const auth = ServiceFactory.getAuthService();
const MINUTES = 10;
const Login = (props) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<FormData>();
  const [isRequestSent, setRequestSent] = useState<boolean>(false);
  const [state, setState] = useState<State>(State.Login);
  const view: IView = views.find((view) => view.state === state) || views[0];
  const updateAuth = useUpdateClientAuth();
  // pass milliseconds and count down interval in milliseconds to useCountDown function
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(MINUTES * 60 * 1000, 1000);

  const onSubmit = handleSubmit(async ({ phone, password }: FormData) => {
    try {
      await auth
        .loginClient({
          phoneNumber: phone,
          password
        })
        .then(() => {
          updateAuth();
          // close modal
          props.setModal({ ...props.modal, show: false });
        });
    } catch {}
  });

  const getMins = (time: number) => {
    const mins = Math.floor(time / 1000 / 60);
    return `${mins < 10 ? "0" : ""}${mins}`;
  };

  const getSecs = (time: number) => {
    const secs = (time / 1000) % 60;
    return `${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <CCardGroup>
      <CCard className="p-4 border-0">
        <CCardBody className="p-0">
          <CRow className="justify-content-between px-3">
            <h1>{t(`${view.name}:title`)}</h1>
            {view.next[0] !== State.None && (
              <span className="new-account-text btn-link" onClick={() => setState(view.next[0])}>
                {t(`${view.name}:nextTitle`)}
              </span>
            )}
            {view.state === State.NewPass && (
              <span className="new-account-text">{`${getMins(timeLeft)}:${getSecs(timeLeft)}`}</span>
            )}
          </CRow>
          <p className="text-muted">{t(`${view.name}:subtitle`)}</p>
          <CForm onSubmit={onSubmit}>
            {view.state === State.Register && (
              <>
                <Select
                  inputMd={12}
                  control={control}
                  name={"country"}
                  placeholder={t(`${view.name}:fields:country:placeholder`)}
                  options={[{ label: "Azerbaijan", value: 1 }]}
                />
                <Select
                  inputMd={12}
                  control={control}
                  name={"city"}
                  placeholder={t(`${view.name}:fields:city:placeholder`)}
                  options={[{ label: "Baku", value: 1 }]}
                />
              </>
            )}
            {view?.fields.map(({ name, icon, type }) => (
              <Input
                key={name}
                inputMd={12}
                prepend={<CIcon icon={icon} className="auth-icon" />}
                control={control}
                name={name}
                type={type || name}
                placeholder={t(`${view.name}:fields:${name}:placeholder`)}
                required={t(`${view.name}:fields:${name}:required`)}
                min={0}
              />
            ))}
            <CRow>
              <CCol xs="6" className="pr-0">
                <CButton
                  color="primary"
                  disabled={isRequestSent}
                  className="px-4"
                  type="submit"
                  onClick={() => view.state === State.ForgotPass && start()}
                >
                  {t(`${view.name}:confirm`)}
                </CButton>
              </CCol>
              <CCol xs="6" className="text-right pl-0">
                {view.next[1] !== State.None && (
                  <CButton color="link" className="px-0" onClick={() => setState(view.next[1])}>
                    {t(`${view.name}:fallback`)}
                  </CButton>
                )}
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
};

export default Login;
