import { createContext, useContext, useEffect, useState } from "react";
import { IEmployee } from "./utils/api/types/interfaces";
import ServiceFactory from "./utils";
import { ClientSession } from "./utils/api/types/responseTypes/Auth";

const api = ServiceFactory.getAuthService();

const ClientAuthContext = createContext<any>([]);
const UpdateClientAuthContext = createContext<Function>(() => {});

const AdminAuthContext = createContext<any>([]);
const UpdateAdminAuthContext = createContext<Function>(() => {});

export function useClientAuth(): any {
  return useContext(ClientAuthContext);
}

export function useUpdateClientAuth(): Function {
  return useContext(UpdateClientAuthContext);
}

export function useAdminAuth(): any {
  return useContext(AdminAuthContext);
}

export function useUpdateAdminAuth(): Function {
  return useContext(UpdateAdminAuthContext);
}

export function ClientAuthProvider({ children }) {
  const [client, setClient] = useState<ClientSession | undefined>();
  const [isFetchingClient, setFetchingClient] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // TODO: uncomment effect once client login is enabled again
  // useEffect(() => {
  //   fetchClient();
  // }, []);

  const fetchClient = () => {
    setFetchingClient(true);
    api
      .checkClientSession()
      .then((data) => {
        setClient(data);
      })
      .catch(() => {
        setClient(undefined);

        setError("failed to load Client!");
      })
      .finally(() => {
        setFetchingClient(false);
      });
  };

  return (
    <ClientAuthContext.Provider value={{ client, isFetchingClient, error }}>
      <UpdateClientAuthContext.Provider value={fetchClient}>{children}</UpdateClientAuthContext.Provider>
    </ClientAuthContext.Provider>
  );
}

export function AdminAuthProvider({ children }) {
  const [admin, setAdmin] = useState<IEmployee | undefined>();
  const [isFetchingAdmin, setFetchingAdmin] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    fetchAdmin();
  }, []);

  const fetchAdmin = () => {
    console.log("updating....");
    setFetchingAdmin(true);
    api
      .checkEmployeeSession()
      .then((data: IEmployee) => {
        setAdmin(data);
      })
      .catch(() => {
        setAdmin(undefined);
        setError("failed to load Admin!");
      })
      .finally(() => {
        setFetchingAdmin(false);
      });
  };

  return (
    <AdminAuthContext.Provider value={{ admin, isFetchingAdmin, error }}>
      <UpdateAdminAuthContext.Provider value={fetchAdmin}>{children}</UpdateAdminAuthContext.Provider>
    </AdminAuthContext.Provider>
  );
}
