import { createContext, useContext, useState } from "react";
// import { storage } from "../../../adminPanel/users/constants";
import StorageService from "../../../utils/api/StorageService";
const storage = new StorageService();
const TableConfigurationsContext = createContext([] as string[]);
const UpdateTableConfigurationsContext = createContext((field: string) => {});

export function useCheckboxes() {
  return useContext(TableConfigurationsContext);
}

export function useUpdateCheckboxes() {
  return useContext(UpdateTableConfigurationsContext);
}

export function TableConfigurationsProvider({ children, tableName }) {
  const [checkboxes, setCheckboxes] = useState<string[]>(storage.getActiveTableFields(tableName));

  const toggleCheckboxes = (field: string) => {
    let position, newCheckboxes;

    try {
      position = checkboxes.indexOf(field);
      newCheckboxes = checkboxes.slice();

      if (position !== -1) {
        newCheckboxes.splice(position, 1);
      } else {
        newCheckboxes = [...checkboxes, field];
      }
    } catch {
      console.error("Add default table fields array in /src/containers/UIElements/constants.ts.");
    }

    setCheckboxes(newCheckboxes);

    //save new results in the local storage
    storage.updateActiveTableFields(tableName, newCheckboxes);
  };

  return (
    <TableConfigurationsContext.Provider value={checkboxes}>
      <UpdateTableConfigurationsContext.Provider value={toggleCheckboxes}>
        {children}
      </UpdateTableConfigurationsContext.Provider>
    </TableConfigurationsContext.Provider>
  );
}
