import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ServiceFactory from "../utils";

const auth = ServiceFactory.getAuthService();
const TheHeaderDropdown = () => {
  return (
    <CDropdown inNav className="c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon size="xl" name="cil-menu" />
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end">
        <CDropdownItem to="/settings">
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem
          onClick={async () => {
            try {
              await auth.logoutClient();
            } catch (error) {}
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
