import { CHeader, CToggler, CHeaderBrand, CHeaderNav, CLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ServiceFactory from "../utils";
import { useTranslation } from "react-i18next";
import { useUpdateAdminAuth } from "../AuthContext";

const api = ServiceFactory.getAuthService();
const TheHeader = ({ sidebar, setSidebar }) => {
  const updateAuth = useUpdateAdminAuth();

  const { t } = useTranslation();
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebar.sidebarShow) ? false : "responsive";
    setSidebar({ sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebar.sidebarShow) ? true : "responsive";
    setSidebar({ sidebarShow: val });
  };

  const handleClick = () => {
    api.logoutEmployee().finally(() => updateAuth());
  };

  return (
    <CHeader withSubheader>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height={48} />
      </CHeaderBrand>

      <CHeaderNav className="ml-auto px-3">
        <div className="d-md-down mfe-2 c-subheader-nav">
          <CLink className="c-subheader-nav-link" onClick={handleClick}>
            &nbsp;{t(`layout:header:logout`)}
          </CLink>
        </div>
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeader;
